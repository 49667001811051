<template>
	<div class="login">
		<!-- 登录窗口 -->
		<div class="login_box">
			<div class="title">乡村振兴产业综合服务</div>
			<el-form ref="form" :model="params" :rules="rules" label-width="80px" label-position="top" class="form">
				<el-form-item prop="username" :error="errorMsg">
					<el-input v-model="params.username" placeholder="请输入用户名" 
						@keyup.enter.native="handleLogin('form')" clearable prefix-icon="el-icon-user">
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input v-model="params.password" placeholder="请输入密码" 
						@keyup.enter.native="handleLogin('form')" show-password prefix-icon="el-icon-lock">
					</el-input>
				</el-form-item>
			</el-form>
			<el-button class="btn_login" :loading="isLoading" @click="handleLogin('form')">登录</el-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			params: {
				username: null,
				password: null
			},
			rules: {
				username: [
					{ required: true, message: '请填写用户名', trigger: 'change' }
				],
				password: [
					{ required: true, message: '请输入密码', trigger: 'change' }
				],
			}, //表单验证规则
			isLoading: false,
			errorMsg: '',
			routerFromPath: ''
		}
	},
	methods: {
		async handleLogin(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					console.log(this.params)
					
					// 加载开启
					this.isLoading = true;
					
					if(this.params.username === 'admin' && this.params.password === 'ZSCN88') {
						setTimeout(() => {
							this.isLoading = false;
							this.$message.success("登录成功")
							let token = '123456';
							// 将用户token保存到vuex中
							this.$store.commit('login/changeLogin', { token: token });
							this.$router.push({
								path: '/smartAgriculture'
							})
						}, 3000)
					} else {
						this.$nextTick(() => {
							this.$message.error("登录失败")
							this.errorMsg = '账户密码错误';
							this.isLoading = false;
						})
					}
				}
			});
		}
	},
}
</script>

<style lang="scss" scoped>
	.login {
		background-image: url(./../assets/img/login_bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 100%;
		position: relative;
		.login_box {
			background-image: url(./../assets/img/login_info_bg.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 750px;
			height: 750px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			.title {
				font-size: 22px;
				color: #fff;
				text-align: center;
				margin-top: 250px;
				letter-spacing: 2px;
				text-shadow: 3px 2px 5px #1F71FF, 1px 1px 1px #6BA1FF;
			}
			.form {
				width: 40%;
				margin: 45px auto 0;
			}
			.btn_login {
				background: transparent;
				border: none;
				color: #fff;
				letter-spacing: 5px;
				font-size: 20px;
				position: absolute;
				top: 72%;
				transform: translate(-50%,-50%);
				cursor: pointer;
			}
		}
	}
	/deep/ .el-input__inner {
		height: 50px !important;
		line-height: 50px !important;
		padding-left: 50px !important;
		padding-right: 50px !important;
		font-size: 15px !important;
		background-color: transparent !important;
		color: #6BA1FF;
		border: none !important;
		border-bottom: 1px solid #6BA1FF !important;
		border-radius: unset !important;
	}
	/deep/ .el-form-item {
		margin-bottom: 8px !important;
	}
	/deep/ .el-input__suffix {
		right: 15px !important;
	}
	/deep/ .el-input__prefix {
		left: 10px !important;
	}
	/deep/ .el-input__icon {
		line-height: 50px !important;
		color: #6BA1FF !important;
	}
	/deep/ .el-form-item__error {
		padding-top: 10px !important;
		position: unset !important;
		text-align: left;
	}
	/deep/.el-input__inner::placeholder {
	    color: #6BA1FF;
	}
	    /* 谷歌 */
	/deep/ .el-input__inner::-webkit-input-placeholder {
	    color: #6BA1FF;
	}
	    /* 火狐 */
	/deep/ .el-input__inner:-moz-placeholder {
	    color: #6BA1FF;
	}
	    /*ie*/
	/deep/ .el-input__inner:-ms-input-placeholder {
	    color: #6BA1FF;
	}
	/deep/ .el-button.is-loading:before {
		background-color: transparent !important;
	}
</style>